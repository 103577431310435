const SkeletonLoader = () => (
   <div className="loading-container">
      <div className="loading-box"></div>
      <div className="loading-box"></div>
      <div className="loading-box"></div>
      <div className="loading-box"></div>
    </div>
);

export default SkeletonLoader;
